import React, { useState, useEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import styled from '@emotion/styled'
import carousel_A from '../images/carousel/carousel_A.png'
import carousel_B from '../images/carousel/carousel_B.png'
import carousel_C from '../images/carousel/carousel_C.png'
import carousel_D from '../images/carousel/carousel_D.png'
import bp from '../components/common/breakpoints'

const CarouselContainer = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
  z-index: 1;
  @media (max-width: 576px) {
    display: none
  }
  @media (min-width: 645px) {
    top: 68px;
  }
  ${bp[2]} {
    top: 38px;
  }
  ${bp[3]} {
    top: 68px;
  }
`

const CarouselImg = styled.img`
  width: 100%;
  ${bp[0]} {
    height: 350px;
  }
  ${bp[1]} {
    height: 350px;
  }
  ${bp[2]} {
    height: 350px;
  }
  ${bp[3]} {
    height: 450px;
  }
  ${bp[4]} {
    height: 450px;
  }
`

const CarouselImages = () => {
  return (
    <CarouselContainer>
      <Carousel style={{
        width: '100%', position: 'absolute', opacity: '50%'
      }}>
        <Carousel.Item>
          <CarouselImg src={carousel_A} />
        </Carousel.Item>
        <Carousel.Item>
          <CarouselImg src={carousel_B} />
        </Carousel.Item>
        <Carousel.Item>
          <CarouselImg src={carousel_C} />
        </Carousel.Item>
        <Carousel.Item>
          <CarouselImg src={carousel_D} />
        </Carousel.Item>
      </Carousel>
    </CarouselContainer>
  )
}

export default CarouselImages
